  .contact {
    padding: 50px 0;
  }
  
  .call-to-action {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(210, 63, 93);
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }
  
  .form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgb(28,28,28);
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form input,
  .form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #353d45;
    border-radius: 0.5rem;
    background-color: #333;
    color: white;
  }
  
  .form textarea {
    resize: vertical;
  }
  
  .send__button {
    display: block;
    width: 40%;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: auto;
  }
  
  .send__button:hover {
    background-color: rgb( 210, 63, 93);
  }
  
  .home__scroll-up {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .wheel {
    animation: scroll 2s ease infinite;
  }

  @keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
  }

  .home__scroll-name- {
    color: var(--title-color);
    font-weight: var(--font-medium);
  }

  .home__scroll-arrow {
    font-size: 1.25rem;
    color: var(--title-color);
  }

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
  .form {
    margin-right: -8rem;
  }

  .call-to-action {
    margin-left: 16.5rem;
    margin-right: -16.5rem;
  }

  .home__scroll-up {
    display: none;
  }
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
  .form {
      width: 350px;
  }

  .send__button {
    width: max-content;
  }

  .home__scroll-up {
    display: none;
  }
}