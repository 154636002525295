.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.about__img {
    background: url(../../assets/Memoji-Ponder.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    justify-self: center;
    order: 0;
    width: 300px;
    height: 300px;
    animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.about__info {
    grid-template-columns: repeat(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.about__box {
    background-color: rgb(28, 28, 28);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon {
    font-size: 1.5rem;
    color: rgb(210, 63, 93);
    margin-bottom: var(--mb-0-5);
}

.about__title {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    color: rgb(210, 63, 93);
}

.about__title2 {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    color: rgb(210, 63, 93);
}

.about__subtitle {
    font-size: var(--tiny-font-size);
}

.about__description {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
    text-align: center;
}

.button--flex-cv {
    display: inline-flex;
    background-color: rgb(28, 28, 28);
    color: rgb(210, 63, 93);
    justify-content: center;
}

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
    .about__container {
        margin-right: -20rem;
    }

    .about__img {
        width: 200px;
        height: 200px;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        margin-left: 2.5rem;
        margin-bottom: 2rem;
    }

    .about__info {
        margin-right: 2rem;
    }
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
    .about__container {
        grid-template-columns: repeat(1, 1fr);
    }

    .about__container .about__title {
        font-size: xx-large;
    }
    
    .about__img {
        width: 200px;
        height: 200px;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
    }

    .about__info {
        grid-template-columns: repeat(3, 110px);
        gap: 0.5rem;
        margin-bottom: var(--mb-2);
    }

    .about__description {
        margin-left: 1rem;
        margin-right: 1rem;
    }
  }