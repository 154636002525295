.portfolio__container {
    grid-template-columns: repeat(3, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.project__content {
    background-color: rgb(28,28,28);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    border-radius: 1.25rem;
}

.project__title {
    color: rgb(210,63,93);
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
}

.project__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
}

.project__group {
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
}

.project__data {
    display: flex;
    gap: 0.5rem;
}

.project__name {
    color: white;
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
    text-align: center;
}

.project__description {
    color: white;
    font-size: var(--tiny-font-size);
    font-weight: var(--font-medium);
    display: flex;
    text-align: center;
}

.project__img {
    border-radius: 0.5rem;
    justify-self: center;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.project__img-data {
    border-radius: 0.5rem;
    justify-self: center;
    filter: brightness(250%);
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.button-learnMore {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-color: #333;
    margin-top: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;
    border-radius: 0.3rem;
    padding: 0.25rem;
}

.button-learnMore:hover {
    transition: 0.2s;
    background-color: rgb(210,63,93);
}

/* ===================== BREAKPOINTS ===================== */

/*      M Laptop Devices       */
@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .portfolio__container {
        grid-template-columns: repeat(3, 300px);
        column-gap: 1rem;
        justify-content: center;
        margin-bottom: -1rem;
        position: relative;
    }
}

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width:999px) {
    .portfolio__container {
        grid-template-columns: repeat(1, 350px);
        column-gap: 3rem;
        justify-content: center;
        margin-right: -22.5rem;
        margin-bottom: -1rem;
    }
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
    .portfolio__container {
        grid-template-columns: repeat(1, 350px);
        column-gap: 3rem;
        justify-content: center;
        margin-bottom: -1rem;
    }
}