.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: rgb(28, 28, 28);
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
    color: white;
    font-weight: var(--font-medium);
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: white;
    font-weight: bold;
    transition: 0.3s;
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

/* Active Link */
.active-link,
.nav__link:hover {
    color: rgb(210, 63, 93);
}

/* ===================== BREAKPOINTS ===================== */

/*   For Mobile Devices   */
@media screen and (max-width: 475px) {
    .header {
        top: initial;
    }

    .nav__menu {
        position: fixed;
        height: max-content;
        bottom: 100%;
        left: 0;
        width: 100%;
        background-color: rgb(210, 63, 93);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: 0.3s;
    }

    /* Show Menu */
    .show-menu {
        top: 0;
    }

    .nav__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        margin-bottom: -3rem;
    }

    .van__icon {
        font-size: 1.2rem;
    }

    .nav__close {
        position: absolute;
        right: 1.3rem;
        bottom: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: rgb(28, 28, 28);
    }

    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
        margin-right: 1rem;
    }

    .nav__logo {
        margin-left: 1rem;
    }

    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }
}