.skills__container {
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.skills__content {
    background-color: rgb(28, 28, 28);;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    border-radius: 1.25rem;
}

.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
    color: rgb(210, 63, 93);
}

.skills__box {
    display: grid;
    justify-content: center;
    column-gap: 2.5rem;
}

.skills__group {
    display: grid;
    align-items: center;
    row-gap: 1rem;
}

.skills__data {
    display: flex;
    gap: 0.5rem;
}

.skills .bx-badge-check {
    font-size: 1rem;
    color: white;
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
    color: white;
}

.skills__level {
    font-size: var(--tiny-font-size);
}

/* ===================== BREAKPOINTS ===================== */

/*   For Large Devices   */
@media screen and (min-width: 476px) and (max-width:999px) {
    .skills__container {
        grid-template-columns: 2,1fr;
        margin-right: -22.5rem;
    }

    .skills__content {
        padding: 1.5rem;
    }
}
  
/*        For Mobile Devices      */
@media screen and (max-width: 475px) {
    .skills__container {
        grid-template-columns: repeat(1, 350px);
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .skills__content {
        padding: 1.5rem;
    }
}