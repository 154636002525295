.footer {
    background-color: rgb(28, 28, 28);
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer p {
    margin: 0;
    font-size: 14px;
  }
  
  .footer__links {
    margin-top: 10px;
  }
  
  .footer__links a {
    color: rgb(210,63,93);
    text-decoration: none;
    margin: 0 10px;
    font-size: 16px;
  }
  
  .footer__links a:hover {
    color: white;
  }  

/* ===================== BREAKPOINTS ===================== */

/*   For Large Devices   */
@media screen and (min-width: 476px) and (max-width:999px) {
  .footer {
    height: 5rem;
    width: 170%; /* Ensure the footer covers the entire width */
    position: relative; /* Fix the footer at the bottom of the viewport */
    bottom: 0;
    left: 0;
    background-color: rgb(28, 28, 28);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer p {
    font-size: smaller;
    display: inline;
  }

  .footer__links a {
    color: rgb(210,63,93);
  }
}

/*        Mobile Devices      */
@media screen and (max-width: 475px) {
  .footer {
    height: 100%;
    width: 100%;
  }
  
  .footer p {
    font-size: smaller;
    display: wrap;
  }

  .footer__links a {
    color: rgb(210,63,93);
  }
}