.home__container {
    margin-top: 2rem;
    row-gap: 7rem;
    justify-content: center;
}

.home__content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: center;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    margin-left: 3rem;
    font-size: 1.25rem;
    color: rgb(210, 63, 93);
}

.home__social-icon:hover {
    color: white;
}

.button--flex-hi {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-left: 9rem;
    margin-right: 9rem;
    background-color: rgb(28, 28, 28);
    color: rgb(210, 63, 93);
}

.home__title {
    font-size: xxx-large;
    color: rgb(210, 63, 93);
    margin-bottom: var(--mb-0-25);
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.animated-text::after {
    content: "|";
    animation: cursor-blink 1.5s step-end infinite;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes cursor-blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
.cursor {
    display: inline-block;
    width: 3px;
    height: 1em;
    margin-left: 2px;
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
    color: rgb(210, 63, 93);
    display: block;
    text-align: center;
    font-weight: bold;
}

.home__subtitle::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background-color: rgb(210, 63, 93);;
    left: 3rem;
    top: 1rem;
}

.home__subtitle::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background-color: rgb(210, 63, 93);;
    right: 3rem;
    top: 1rem;
}

.home__description {
    position: relative;
    max-width: 100%;
    text-align: center;
}

.home__img {
    background: url(../../assets/Memoji-Wink.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.home__scroll {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
}

.home__scroll-name {
    color: rgb(210, 63, 93);
    font-weight: var(--font-medium);
    margin-right: var(--mb--0-25);
    margin-left: 2px;
}

.home__scroll-arrow {
    font-size: 1.25rem;
}

/* ===================== BREAKPOINTS ===================== */

/*      Tablet Devices      */
@media screen and (min-width: 476px) and (max-width: 999px) {
    .home__container {
        margin-right: -25rem;
    }

    .home__img {
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 200px;
        height: 200px;
    }

    .home__scroll {
        display: none;
    }

    .home__social {
        display: inline-flex;
        flex-direction: column;
    }
}

  
/*      Mobile Devices      */
@media screen and (max-width: 475px) {
    .home__container {
        margin-top: -0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home__img {
        order: 0;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 200px;
        height: 200px;
    }

    .home__social {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        margin-right: 2rem;
    }
    
    .home__title {
        font-size: xx-large;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .home__subtitle {
        font-size: large;
    }

    .home__subtitle::before, 
    .home__subtitle::after {
        width: 30px;
    }

    .home__description {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .home__scroll {
        display: none;
    }

    .button--flex-hi {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        margin: 2rem auto;
        margin-bottom: -2.5rem;
    }
}

